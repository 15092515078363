<template>
  <div class="info">

    <div class="">
      <van-cell-group>
        <van-field  v-model="dataForm.idNumber"  :readonly="!isEdit" label="证件号"  required placeholder="请输入"  input-align="right" :class="isOld == 1 ? 'oldSize' : 'widthSet'" @input="idNumberChange"/>
        <van-field  v-model="dataForm.name" :readonly="!isEdit" label="姓名"  placeholder="请输入"  input-align="right" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-field  v-model="dataForm.mobile" :readonly="!isEdit" label="手机号" required placeholder="请输入"  input-align="right" :class="isOld == 1 ? 'oldSize' : ''" @input="mobileChange"/>
        <van-field  v-if="type == 2" v-model="dataForm.relationShip" :readonly="!isEdit" label="关系" required placeholder="请输入"  input-align="right" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell v-if="type == 1" name="与房主关系" title="与房主关系" :border="false" :value="dataForm.relationshipStr" @click="!isEdit ? false : relationshipShow = true" placeholder="请选择与户主关系" :is-link="!!isEdit" required/>
        <van-popup v-model="relationshipShow" position="bottom">
          <van-picker
              show-toolbar
              value-key="label"
              :columns="relationshipList"
              @confirm="relationshipConfirm"
              @cancel="relationshipShow = false"
          />
        </van-popup>
        <van-cell title="现居住地" :border="false" class="cellClass"/>
        <van-field clearable clear-trigger="always" v-model="dataForm.nowAddress" :readonly="!isEdit" placeholder="请输入" style="text-align: right"/>
      </van-cell-group>
      <van-row class="updateBtn" v-if="!!isEdit">
        <van-col span="12" style="padding-right: 0.2rem;">
          <van-button type="danger" round block plain @click="onSubmit(1)" :style="{fontSize: isOld == 1 ? '18px' : '16px'}">删除</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" round block @click="onSubmit()" :style="{fontSize: isOld == 1 ? '18px' : '16px'}">完成</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {getImageStream} from '@/utils/index'
import {formatterDate} from '@/utils/utils'
import {isNULL, IdentityCodeValid, isMobile} from '@/utils/validate'
export default {
  components: {
  },
  data () {
    return {
      dataForm: {
        id: 0,
        userId: 0,
        idNumber: '',
        name: '',
        mobile: '',
        nowAddress: '',
        relationShip: '',
        relationshipStr: '请选择'
      },
      type: 0,
      idNumberCheck: false,
      mobileCheck: false,
      relationshipShow: false,
      relationshipList: [],
      isOld: null,
      isEdit: true,
    }
  },
  methods: {
    getRelation() {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual`),
        method: 'get',
        params: this.$http.adornParams({
          code: 'userRelationshipInfo'
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.relationshipList = data.virtualDicts
        }
      })
    },
    getDetailInfo (data) {
      if (this.type == 1) {
        this.dataForm.userId = data.userId
        this.dataForm.idNumber = data.idCard
        this.dataForm.name = data.name
        this.dataForm.mobile = data.mobile
        this.dataForm.nowAddress = data.nowAddress
        this.dataForm.relationshipStr = data.relationStr
        this.dataForm.relationShip = data.relation
      } else {
        this.dataForm.idNumber = data.relationsIdNumber
        this.dataForm.name = data.relationsName
        this.dataForm.mobile = data.relationsMobile
        this.dataForm.nowAddress = data.relationsNowAddress
        this.dataForm.relationShip = data.relations
      }
      this.dataForm.id = data.id
    },
    onSubmit (status) {
      this.$dialog.confirm({
        message: `${status ? '确认删除？' : '确认提交？'}`,
      })
      .then(() => {
        if (this.dataForm.idNumber.trim() == '') {
          return this.$toast.fail('请输入有效证件号')
        }
        if (this.dataForm.mobile.trim() == '') {
          return this.$toast.fail('请输入手机号码')
        }
        if (this.type == 1 && this.dataForm.relationshipStr == '请选择') {
          return this.$toast.fail('请选择与房主关系')
        }
        if (this.type == 2 && this.dataForm.relationShip == '') {
          return this.$toast.fail('请输入关系')
        }
        let removeList = []
        if (status) {
          removeList.push(this.dataForm.id)
        }
        let relations = [{
          id: this.dataForm.id || undefined,
          userId: this.dataForm.id ? this.dataForm.userId : undefined,
          idCard: this.dataForm.idNumber,
          name: this.dataForm.name,
          mobile: this.dataForm.mobile,
          nowAddress: this.dataForm.nowAddress,
          relation: this.dataForm.relationShip,
        }]
        let url = '/wxapp/user/relationship/save'
        if (this.type == 2) {
          url = '/wxapp/user/socialRelations/saveList'
          relations = [{
            id: this.dataForm.id ? this.dataForm.id : undefined,
            userId: this.dataForm.userId,
            relationsIdNumber: this.dataForm.idNumber,
            relationsName: this.dataForm.name,
            relationsMobile: this.dataForm.mobile,
            relationsNowAddress: this.dataForm.nowAddress,
            relations: this.dataForm.relationShip,
            status: status
          }]
        }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          removeIds: this.type == 1 && removeList.length > 0 ? removeList.toString() : undefined,
          userId: this.type == 1 ? this.dataForm.userId : undefined,
        }),
        data: this.$http.adornData(relations, false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('操作成功')
          this.$router.go(-1)
        } else {
        this.$toast.fail(data.msg);
        }
      })
      })
      .catch(() => {
      });
    },
    onCancel () {
      this.$router.go(-1)
    },
    mobileChange(value){
      if (isMobile(value) && !isNULL(value)) {
        this.mobileCheck = true
      }
    },
    idNumberChange(value){
      if (IdentityCodeValid(value) && !isNULL(value)) {
        this.idNumberCheck = true
      }
    },
    relationshipConfirm (value) {
      this.dataForm.relationshipStr = value.label
      this.dataForm.relationShip = value.value
      this.relationshipShow = false
    },
  },
  created () {
    this.getRelation()
    this.dataForm.userId = this.$route.query.userId || 0
    this.type = parseInt(this.$route.query.type) || 0
    this.isEdit = this.$route.query.isEdit == 'true' ? true : false
    if (this.$route.query.data !== 'null') {
      let data = JSON.parse(decodeURIComponent(this.$route.query.data))
      this.getDetailInfo(data)
    }
    if (this.type == 1) {
      sessionStorage.setItem('changeType', 3)
    } else {
      sessionStorage.setItem('changeType', 4)
    }
  }
}

</script>
<style lang="scss" scoped>
.info {
  ::v-deep .van-field__control {
    line-height: unset !important;
  }
}
  .headbox {
    background-color: #fff;
    padding-right: 20px;
    //margin: 30px;
    //padding: 30px;
    .Avatar {
      width: 100%;
      text-align: center;
      img {
        width: 120px;
        height: 120px;
        overflow: hidden;
      }
    }
    .headImgbtn {
      height: 80px;
      margin-top: 30px;
      button {
        width: 180px;
        height: 70px;
      }
    }
  }
  .blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.updateBtn {
  position: fixed;
  bottom: 40px;
  width: 100%;
  height: 88px;
  display: flex;
  padding: 0 20px;
  margin-top: 78px;
  .van-button {
    background: #4581F8;
    border-radius: 8px;
  }
  .van-col:first-child .van-button{
    background-color: #fff !important;
  }
}
.redTip {
  color: red;
}
.blackTip {
  color: #333333;
}
</style>
